<template>
  <div class="product-card" style="margin: 20px;">
    <b-skeleton-wrapper :loading="isProductsLoading">
      <template #loading>
        <ProductCardSkeleton />
      </template>
      <router-link class="product-card__title" :to="{ name: 'layout.product', params: { slug: product.slug } }">
        <div
          class="product-card__img"
          :class="{ 'no-photo': !imgUrl }"
          :style="{ backgroundImage: `url(${imgUrl ? imgUrl : require('@/assets/images/no-photo.jpeg')})` }"
        >
          <!--<img v-if="imgUrl" :src="imgUrl" alt="product-image" />-->
          <!--<img v-else src="@/assets/images/no-photo.jpeg" alt="No photo" />-->
          <!--<div class="img-darkness"></div>-->
        </div>
      </router-link>
      <div class="product-card__rating">
        <Rating :rating="product.rating" />
        <div v-if="showFavoriteIcon" @click="deleteFromFavorites(product.id)" class="favorite-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            fill="currentColor"
            class="bi bi-heart-fill"
            viewBox="0 0 16 16"
          >
            <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
          </svg>
        </div>
      </div>
      <div class="product-card__title-wrapper">
        <span class="product-card__price"> {{ formatPrice(product.price) }} ₸</span>
        <router-link class="product-card__title" :to="{ name: 'layout.product', params: { slug: product.slug } }"
          >{{ product.name }}
        </router-link>
        <router-link class="product-card__title" :to="{ name: 'layout.product', params: { slug: product.slug } }">
          <button >В корзину</button>
        </router-link>
       
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import ProductCardSkeleton from './ProductCardSkeleton'
import Rating from '@/components/Rating'
import Api from '@/services/api'

export default {
  name: 'ProductCard',
  components: {
    ProductCardSkeleton,
    Rating,
  },

  props: {
    product: {
      type: Object,
      required: false,
      default: () => ({ name: '', images: [], price: '', rating: '0' }),
    },
    showFavoriteIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    isProductsLoading: true,
    leftover: null,
  }),

  computed: {
    isProductLeftInStock() {
      return !!(this.leftover && this.leftover.id && this.leftover.quantity)
    },
    cartProducts() {
      return this.$store.state.products.basket
    },
    imgUrl() {
      if (!this.product?.images?.length) return false
      return this.product.images[0].path
    },

    favorites() {
      return this.$store.state.products.favorites
    },

    isLogged() {
      const token = this.$store.state.token
      if (token) return true
      const lsToken = localStorage.getItem('uni_token')
      return !!lsToken
    },
  },

  mounted() {
    
    setTimeout(() => (this.isProductsLoading = false), 1500)
  },

  methods: {
    async checkQuantity(blockUi) {
      const productId = this.product.id
      const config = []
      this.attributes.forEach((el) => {
        const pickedValue = el.value.find((attr) => attr.isPicked)
        config.push({
          attribute_id: el.id,
          value: pickedValue.name,
        })
      })
      const { leftover } = await this.wrapAndHandleRequest(() =>
        Api.checkQuantity({ productId, config: JSON.stringify(config) }, blockUi),
      )
      this.leftover = leftover
    },
    async pickAttribute(id, attrName) {
      const attr = this.attributes.find((el) => el.id === id)
      attr.value.forEach((item) => (item.name === attrName ? (item.isPicked = true) : (item.isPicked = false)))
      await this.checkQuantity(true)
    },
    
    async addToBasket() {
      if (this.isLogged) {
        if (this.cartProducts.find((el) => el.leftover_id === this.leftover.id)) {
          return this.makeToast('Корзина', 'Данный товар уже добавлен в корзину', 'warning', false)
        }
        const cart = JSON.stringify([...this.cartProducts, { leftover_id: this.leftover.id, quantity: 1 }])
        await this.wrapAndHandleRequest(() => Api.updateCart({ cart }))
        const { cart: updatedCart } = await this.wrapAndHandleRequest(Api.fetchCartList, false)
        this.$store.commit('products/SET_BASKET_PRODUCTS', updatedCart)
        return this.makeToast('Корзина', 'Товар был успешно добавлен в корзину', 'success', false)
      }else{
        return this.makeToast('Пожалуйста авторизайтесь', 'Для добоавления в корзину', 'error', false)
      }
    },

    formatPrice(price) {
      return new Intl.NumberFormat('RU-ru').format(Number(price))
    },

    async deleteFromFavorites(id) {
      if (!this.isLogged) {
        this.$store.commit('products/REMOVE_PRODUCT_FROM_PRODUCTS', id)
        this.$nextTick(() => {
          localStorage.setItem('uni_favorites', JSON.stringify(this.favorites))
          return this.makeToast('Избранные', 'Товар был успешно удален из избранных', 'success', false)
        })
      } else {
        await this.wrapAndHandleRequest(() => Api.deleteFromFavorites({ productId: id }))
        this.$store.commit('products/REMOVE_PRODUCT_FROM_PRODUCTS', id)
        this.makeToast('Избранные', 'Товар был успешно удален из избранных', 'success', false)
      }
    },
  },
}
</script>
